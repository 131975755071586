const page = '/p';
const admin = '/a';

const query = (params: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) searchParams.set(key, value);
  }
  return searchParams.size > 0 ? '?' + searchParams.toString() : '';
};

export const paths = {
  landing: '/waitlist',
  earlyAccess: '/early-access',
  makeEarlyAccess: (email?: string) => `/early-access${query({ email })}`,

  privacyPolicy: '/policy',
  termsOfUse: '/terms',
  default: '/',
  xAuth: '/xauth',

  auth: '/auth',
  login: '/auth/login',
  makeLogin: (from?: string) => `/auth/login${query({ from })}`,
  signup: '/auth/signup',
  makeSignup: (from?: string) => `/auth/signup${query({ from })}`,
  resetPassword: '/auth/reset-password',
  makeResetPassword: (from?: string) =>
    `/auth/reset-password${query({ from })}`,

  user: '/:username',
  makeUser: (name: string) => `/${name}`,
  followers: `/:username/followers`,
  makeFollowers: (name: string) => `/${name}/followers`,
  following: `/:username/following`,
  makeFollowing: (name: string) => `/${name}/following`,

  post: `${page}/posts/:id`,
  makePost: (postId: string, commentId?: string) =>
    `${page}/posts/${postId}${query({ commentId })}`,

  tournaments: `${page}/tournaments`,
  makeTournaments: (category: string) =>
    `${page}/tournaments?category=${category}`,
  activeTournaments: `${page}/tournaments/active`,
  makeActiveTournaments: (category: string) =>
    `${page}/tournaments/active?category=${category}`,
  closedTournaments: `${page}/tournaments/closed`,
  makeClosedTournaments: (category: string) =>
    `${page}/tournaments/closed?category=${category}`,
  tournament: `${page}/tournaments/:id`,
  makeTournament: (id: string) => `${page}/tournaments/${id}`,
  submissions: `${page}/tournaments/:id/submissions`,
  makeSubmissions: (id: string) => `${page}/tournaments/${id}/submissions`,
  participants: `${page}/tournaments/:id/participants`,
  makeParticipants: (id: string) => `${page}/tournaments/${id}/participants`,
  discussions: `${page}/tournaments/:id/discussions`,
  makeDiscussions: (id: string) => `${page}/tournaments/${id}/discussions`,

  streams: `${page}/streams`,
  makeStreams: (category: string) => `${page}/streams?category=${category}`,
  followingStreams: `${page}/streams/following`,
  makeFollowingStreams: (category: string) =>
    `${page}/streams/following?category=${category}`,
  followingStreamPosts: `${page}/streams/following/subscriptions`,
  makeFollowingStreamPosts: (category: string) =>
    `${page}/streams/following/subscriptions?category=${category}`,
  createdStreams: `${page}/streams/created`,
  makeCreatedStreams: (category: string) =>
    `${page}/streams/created?category=${category}`,
  paidStreams: `${page}/streams/paid`,
  makePaidStreams: (category: string) =>
    `${page}/streams/paid?category=${category}`,
  stream: `${page}/streams/:id`,
  makeStream: (id: string) => `${page}/streams/${id}`,
  streamDiscussions: `${page}/streams/:id/discussions`,
  makeStreamDiscussions: (id: string) => `${page}/streams/${id}/discussions`,
  streamApi: `${page}/streams/:id/api`,
  makeStreamApi: (id: string) => `${page}/streams/${id}/api`,

  notifications: `${page}/notifications`,
  leaderboard: `${page}/leaderboard`,
  search: `${page}/search`,
  makeSearch: (q?: string, type?: string) =>
    `${page}/search${query({ q, t: type })}`,

  settings: `${page}/settings`,

  settingsAccount: `${page}/settings/account`,
  settingsPersonalDetails: `${page}/settings/account/personal-details`,
  settingsConnectedAccounts: `${page}/settings/account/connected-accounts`,
  settingsSecurity: `${page}/settings/account/security`,
  settingsPassword: `${page}/settings/account/security/password`,

  notificationsSettings: `${page}/settings/notifications`,
} as const;

export const adminPaths = {
  tournaments: `${admin}/tournaments`,
  tournament: `${admin}/tournaments/:id`,
  makeTournament: (id: string) => `${admin}/tournaments/${id}`,
  invites: `${admin}/invites`,
} as const;
